import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Swal from "sweetalert2";
import DfUser from "@/vue/domain/user/df-user";
import DfProperty from "@/vue/domain/content/df-property";
import Utils from "@/vue/infrastructure/utils/helpers";
import Multiselect from "vue-multiselect";
import DfBrand from "@/vue/domain/brand/df-brand";
import { maska } from "maska";

@Component({
  components: {
    Multiselect,
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
  directives: { maska },
})
export default class DfFormRegistrationComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: (): any => null })
  formData!: any;
  @Prop({ type: String, default: "" })
  saveLabel!: string;
  @Prop({ type: Boolean, default: false })
  readOnlyPrivacyPolicy!: boolean;
  @Prop({ type: Boolean, default: false })
  readOnlyClubCardPolicy!: boolean;
  @Prop({ type: Boolean, default: false })
  showRestoreButton!: boolean;

  private formError: any = {};
  private saveWorking = false;
  private geocoderAddresses: Array<any> = [];
  private passwordFieldType: "password" | "text" = "password";
  private passwordObfuscated = true;
  private passwordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";
  private confirmPasswordFieldType: "password" | "text" = "password";
  private confirmPasswordObfuscated = true;
  private confirmPasswordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";
  private addressesAutocomplete: any = {};
  private autosave = false;

  private mask: any = {
    mask: "0000000000",
    lazy: false,
  };

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get clubCardPolicyText() {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_REGISTRATION_CLUB_CARD_TEXT, "TEXT");
  }

  get privacyText() {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_REGISTRATION_PRIVACY_TEXT, "TEXT");
  }

  get properties(): Array<DfProperty> {
    return this.formData.properties || [];
  }

  set properties(properties: Array<DfProperty>) {
    this.formData.properties = properties;
  }

  get user(): DfUser {
    return this.$store.getters.user;
  }

  mounted() {
    if (Utils.getGoogleMapCheckAddressEnable()) {
      this.waitAddressesAutocompleteReady();
    }
  }

  private waitAddressesAutocompleteReady() {
    if (!this.addressesAutocomplete["ADDRESS"]) {
      this.initAddressesAutocomplete("ADDRESS");
    }
    if (!this.addressesAutocomplete["ADDRESS"]) {
      setTimeout(this.waitAddressesAutocompleteReady, 10);
    }
  }

  private async initAddressesAutocomplete(fieldName: string) {
    const field: HTMLInputElement = this.$el.querySelector(`[name=${fieldName}]`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!google.maps.places) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { Place } = await google.maps.importLibrary("places");
    }
    if (!this.addressesAutocomplete[fieldName] && field) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.addressesAutocomplete[fieldName] = new google.maps.places.Autocomplete(field, {
        componentRestrictions: { country: ["it"] },
        fields: ["address_components"],
        types: ["address"],
      });
      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      this.addressesAutocomplete[fieldName].addListener("place_changed", () => {
        this.addressSelection(fieldName);
        if (this.autosave) {
          this.saveFormData(null);
        }
      });
    }
  }

  private saveFormData(event: any) {
    if (event?.type === "keyup" && event?.target?.name === "ADDRESS") {
      this.autosave = true;
      return false;
    }
    this.autosave = false;
    this.saveWorking = true;
    this.$emit("saveFormData", {
      then: () => {
        this.saveWorking = false;
        this.formError = [];
      },
      catch: (error: any) => {
        this.saveWorking = false;
        if (error.response.data.errors) {
          this.formError = [];
          error.response.data.errors.map((errorData: any) => {
            const path: Array<string> = errorData.field.split(".");

            if (path.length > 1) {
              const propertyIndex: string = path[0].substring(path[0].search("\\[") + 1, path[0].search("\\]"));
              const valueIndex: string = path[1].substring(path[1].search("\\[") + 1, path[1].search("\\]"));

              this.formError[this.properties[parseInt(propertyIndex)].code] = [];
              this.formError[this.properties[parseInt(propertyIndex)].code][valueIndex] = `${errorData.objectName}-${errorData.code}`;
            } else {
              const exception01: boolean = errorData.field === "password" && errorData.code === "Pattern";
              const exception02: boolean = errorData.field === "privacyConsent" && errorData.code === "Mandatory";
              const exception03: boolean = errorData.field === "clubCardConsent" && errorData.code === "Mandatory";
              this.formError[errorData.field] = exception01 || exception02 || exception03 ? `${errorData.objectName}-${errorData.field}-${errorData.code}` : `${errorData.objectName}-${errorData.code}`;
            }
          });

          Swal.fire({
            title: this.$t("df-form-registration.modal.save-from-data.error.title").toString(),
            text: this.$t("df-form-registration.modal.save-from-data.error.body").toString(),
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("df-form-registration.modal.save-from-data.error.ok").toString(),
          });
        } else {
          Utils.defaultApiErrorHandler(error, this);
        }
      },
    });
  }

  private showRegistrationPrivacy() {
    Swal.fire({
      title: this.$t("df-newsletter-page.swal.privacy.title").toString(),
      html: this.privacyText,
      icon: "info",
      customClass: "privacy-modal",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: this.$t("df-newsletter-page.swal.privacy.confirm").toString(),
      cancelButtonText: this.$t("df-newsletter-page.swal.privacy.cancel").toString(),
    }).then((result) => {
      Vue.set(this.formData, "privacyConsent", result.value === true);
    });
  }

  private showClubCardPolicy() {
    Swal.fire({
      title: this.$t("df-form-registration.swal.card-club.title").toString(),
      html: this.clubCardPolicyText,
      icon: "info",
      customClass: "privacy-modal",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: this.$t("df-form-registration.swal.card-club.confirm").toString(),
      cancelButtonText: this.$t("df-form-registration.swal.card-club.cancel").toString(),
    }).then((result) => {
      Vue.set(this.formData, "clubCardConsent", result.value === true);
    });
  }

  private loadFile(event: any, propertyValue: any) {
    propertyValue = event.target.files;
  }

  private addField(propertyCode: string) {
    this.properties = this.properties.map((property: DfProperty) => {
      if (property.code === propertyCode) {
        const newValue: any = {};
        newValue[property.typeValue] = null;
        property.values = [...property.values, newValue];
      }
      return property;
    });
  }

  private removeField(propertyCode: string, index: number) {
    this.properties = this.properties.map((property: DfProperty) => {
      if (property.code === propertyCode) {
        property.values.splice(index, 1);
      }
      return property;
    });
  }

  private getTabIndex(propertyIndex: number, fieldIndex: number): number {
    return (propertyIndex + 1) * 50 + fieldIndex;
  }

  private restoreFormData() {
    this.$emit("restoreFormData");
  }

  private async getGeocoderAddress(address: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { Place } = await google.maps.importLibrary("places");
    const request = {
      query: address,
      //fields: ['displayName', 'location', 'businessStatus', 'hasWheelchairAccessibleEntrance'],
      maxResultCount: 7,
      region: "it",
      useStrictTypeFiltering: false,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { places } = await Place.searchByText(request);
    this.geocoderAddresses = places ? places : [];
    if (this.geocoderAddresses.length > 0) {
      this.$refs.tippyGeocoderAddresses && (<any>this.$refs.tippyGeocoderAddresses)[0] ? (<any>this.$refs.tippyGeocoderAddresses)[0].tippy().show() : null;
    } else {
      this.$refs.tippyGeocoderAddresses && (<any>this.$refs.tippyGeocoderAddresses)[0] ? (<any>this.$refs.tippyGeocoderAddresses)[0].tippy().hide() : null;
    }
    console.log(address, places?.length, places);
    /*
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results: any, status: any) => {
      this.geocoderAddresses = results ? results : [];
      if (this.geocoderAddresses.length > 0) {
        this.$refs.tippyGeocoderAddresses && (<any>this.$refs.tippyGeocoderAddresses)[0] ? (<any>this.$refs.tippyGeocoderAddresses)[0].tippy().show() : null;
      } else {
        this.$refs.tippyGeocoderAddresses && (<any>this.$refs.tippyGeocoderAddresses)[0] ? (<any>this.$refs.tippyGeocoderAddresses)[0].tippy().hide() : null;
      }
    });

     */
  }

  private addressSelection(fieldName: string) {
    const address = this.addressesAutocomplete[fieldName].getPlace();
    if (address?.address_components) {
      this.geocoderAddresses = [];
      console.log(address);
      const route: any = address.address_components.find((field: any) => field.types[0] === "route");
      const locality: any = address.address_components.find((field: any) => field.types[0] === "locality");
      const comune: any = address.address_components.find((field: any) => field.types[0] === "administrative_area_level_3");
      const province: any = address.address_components.find((field: any) => field.types[0] === "administrative_area_level_2");
      const country: any = address.address_components.find((field: any) => field.types[0] === "country");
      const postalCode: any = address.address_components.find((field: any) => field.types[0] === "postal_code");
      const streetNumber: any = address.address_components.find((field: any) => field.types[0] === "street_number");

      this.properties.forEach((property: DfProperty) => {
        switch (property.code) {
          case "PROVINCE":
            property.values[0][property.typeValue] = property.listOptions.find((option: any) => option.key === province?.short_name);
            break;
          case "CITY":
            property.values[0][property.typeValue] = comune ? comune.long_name : locality ? locality.long_name : null;
            break;
          case "ADDRESS":
            property.values[0][property.typeValue] = route ? route.long_name : null;
            break;
          case "STREET_NUMBER":
            property.values[0][property.typeValue] = streetNumber ? streetNumber.long_name : null;
            break;
          case "POSTAL_CODE":
            property.values[0][property.typeValue] = postalCode ? postalCode.long_name : null;
            break;
        }
      });
    }
  }

  private resetAddress(fieldCode: string) {
    if (fieldCode === "ADDRESS" && Utils.getGoogleMapCheckAddressEnable() && Utils.getGoogleMapCheckAddressMandatory()) {
      this.properties.forEach((property: DfProperty) => {
        switch (property.code) {
          case "ADDRESS":
            property.values[0][property.typeValue] = null;
            break;
        }
      });
    }
  }

  private changePasswordFieldType() {
    this.passwordObfuscated = !this.passwordObfuscated;
    this.passwordObfuscatedIcon = this.passwordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.passwordFieldType = this.passwordFieldType == "text" ? "password" : "text";
  }

  private changeConfirmPasswordFieldType() {
    this.confirmPasswordObfuscated = !this.confirmPasswordObfuscated;
    this.confirmPasswordObfuscatedIcon = this.confirmPasswordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.confirmPasswordFieldType = this.confirmPasswordFieldType == "text" ? "password" : "text";
  }
}
